import React from "react";

const ListSkeleton = () => {
  return (
    <div>
      <div className="liskSk-circles">
        <div className="LoaderWrapper liskSk-circles-items"></div>
        <div className="LoaderWrapper liskSk-circles-items"></div>
        <div className="LoaderWrapper liskSk-circles-items"></div>
        <div className="LoaderWrapper liskSk-circles-items"></div>
        <div className="LoaderWrapper liskSk-circles-items"></div>
      </div>

      <div className=" LoaderWrapper liskSk-titles"></div>

      <div className="LoaderWrapper liskSk-lists"></div>
      <div className="LoaderWrapper liskSk-lists"></div>
      <div className="LoaderWrapper liskSk-lists"></div>
      <div className="LoaderWrapper liskSk-lists"></div>
      <div className="LoaderWrapper liskSk-lists"></div>
      <div className="LoaderWrapper liskSk-lists"></div>
    </div>
  );
};

export default ListSkeleton;
