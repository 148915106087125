import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Topbar from '../../components/Topbar';
import IndividualMenu from '../../components/IndividualMenu';
import SearchContext from '../../Api/Context/searchContext';
import { serachItems } from '../../Api/common';
import { useState } from 'react';
const Index = () => {
  const { keyword } = useParams();
  const [resultData, setResultData] = useState([])
  const {
    getSuperSubCategoriesSearch,
    searchResults,
    searching,
    searchSuccess,
    searchKeyWords,
  } = useContext(SearchContext);

  const getSearchedItems = async () => {
    let company = localStorage.getItem('company_name')
    
    const res = await serachItems(company, keyword)
    setResultData(res)
  }
  useEffect(() => {
    getSearchedItems();
  }, [keyword]);
  return (
    <>
      <Topbar searchShow={true} />

      <section className='resultsPage'>
        <h6>Search Results ({resultData.length})</h6>
        {searching && <p>Searching.... </p>}
        {searchSuccess && resultData.length < 1 ? (
          <p>No Items Found</p>
        ) : (
          resultData &&
          resultData.map((result) => <IndividualMenu item={result} />)
        )}
      </section>
    </>
  );
};

export default Index;
