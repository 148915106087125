import { SEARCH_FAIL, SEARCH_SUCCESS } from "../types";

const searchReducer = (state = { searchKeyWords: [] }, action) => {
  switch (action.type) {
    case "SEARCH_REQUEST":
      return {
        ...state,
        searching: true,
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        searchSuccess: true,
        searchResults: action.payload,
      };
    case "SEARCH_KEYWORD_REQUEST":
      return {
        ...state,
        searchingKey: true,
        loading: true,
      };
    case "SEARCH_KEYWORD_SUCCESS":
      return {
        ...state,
        loading: false,
        searchKeySuccess: true,
        searchingKey: false,
        searchKeyWords: action.payload,
      };
    case SEARCH_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default searchReducer;
