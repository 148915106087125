import React from 'react';
import Topbar from '../../components/Topbar';
import ComboSlider from '../../components/ComboSlider';
import { useParams } from 'react-router-dom';

const Index = () => {
  const { id } = useParams();
  return (
    <div>
      <Topbar
        searchShow={false}
        backUrl={`${process.env.REACT_APP_PROD_URL}/${id}/home`}
      />

      <ComboSlider />
    </div>
  );
};

export default Index;
