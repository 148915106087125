import React, { useContext, useEffect, useState } from 'react';
import CatItems from '../../components/CatItems';
import CategorySkeleton from '../../microComponents/CategorySkeleton';
import MenusContext from '../../Api/Context/menusContext';
import { Link, useParams } from 'react-router-dom';

const Index = () => {
  const {
    categories,
    getCategories,
    error,
    loading: categoryLoading,
    catSuccess,
  } = useContext(MenusContext);
  const { company_name } = useParams();
  useEffect(() => {
    getCategories(company_name);
    // if (categoryLoading === false) setLoading(false);
  }, [company_name]);
  return !error ? (
    <div>
      {/* {categoryLoading && <CategorySkeleton />} */}
      {!categoryLoading && categories ? (
        categories.map(
          (category, index) =>
            category.toDisplay && (
              <section key={index} className='catgroeyHolder'>
                <h1 className='catgroryTitle'>{category.category_name}</h1>
                <hr />
                <section className='catgroeyHolder-listing'>
                  {category.sub_categories.map((sub, idx) => (
                    <article
                      className='catgroeyHolder-listing-column'
                      key={idx}
                    >
                      <Link
                        to={{
                          pathname: `/subcategories/${sub.subcategory_name}`,
                          state: { subcategory_name: sub.subcategory_name },
                        }}
                      >
                        <CatItems
                          image={`${sub.image}`}
                          name={sub.subcategory_name}
                        />
                      </Link>
                    </article>
                  ))}
                </section>{' '}
              </section>
            )
        )
      ) : (
        <CategorySkeleton />
      )}
    </div>
  ) : (
    <h1>{error}</h1>
  );
};

export default Index;
