import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import CartContext from '../../Api/Context/cartContext';

const Index = () => {
  const { cartItems } = useContext(CartContext);
  return (
    <div>
      <Link to='/cart'>
        <section className='cartWrapper'>
          <div className='cartWrapper-nos'>
            <i class='fa fa-shopping-cart '> </i>
            <span className='m-1'>
              (
              {cartItems &&
                cartItems
                  .map((item) => item.quantity)
                  .reduce((acc, index) => acc + index, 0)}
              )
            </span>
          </div>
          <div>
            View cart<i class='fa fa-play m-2'></i>
          </div>
        </section>
      </Link>
    </div>
  );
};

export default Index;
