import Cookies from "js-cookie";
import { useReducer } from "react";
import TopBarContext from "../Context/topbarContext";
import TopBarReducer from "../Reducers/topBarReducers";
import { SET_CURRENT_ROUTE, SET_PREVIOUS_ROUTE } from "../types";
const TopBarState = (props) => {
  const initialState = {
    loading: true,
    previousRoute: "#",
    currentRoute: "#",
    sliderClick: false,
  };

  const [state, dispatch] = useReducer(TopBarReducer, initialState);
  const setPreviousRoute = (routename) => {
    dispatch({
      type: SET_PREVIOUS_ROUTE,
      payload: routename,
    });
    Cookies.set("previousRoute", routename);
  };
  const setCurrentRoute = (routename) => {
    dispatch({
      type: SET_CURRENT_ROUTE,
      payload: routename,
    });
    Cookies.set("currentRoute", routename);
  };

  const clickSlider = () => {
    state.sliderClick = true;
    dispatch({ type: "SET_CLICK_SLIDER", payload: state.sliderClick });
  };

  return (
    <TopBarContext.Provider
      value={{
        loading: state.loading,
        previousRoute: state.previousRoute,
        currentRoute: state.currentRoute,
        setPreviousRoute,
        setCurrentRoute,
        clickSlider,
      }}
    >
      {props.children}
    </TopBarContext.Provider>
  );
};

export default TopBarState;
