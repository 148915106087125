import React from "react";
import ReactDOM from "react-dom";
import App from "./containers/App";
import { BrowserRouter as Router } from "react-router-dom";
import "../src/styles/App.scss";
import MenusState from "./Api/States/menusState";
import ComboState from "./Api/States/comboState";
import TopBarState from "./Api/States/topbarState";
import CartState from "./Api/States/cartState";
import SearchState from "./Api/States/searchState";

ReactDOM.render(
  <Router>
    <CartState>
      <SearchState>
        <TopBarState>
          <MenusState>
            <ComboState>
              <App />
            </ComboState>
          </MenusState>
        </TopBarState>
      </SearchState>
    </CartState>
  </Router>,
  document.getElementById("root")
);
