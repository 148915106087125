import axios from "axios"

export const getItemCategory = async (company) => {
    try {
        const response = await axios.post('/api/method/sajha_menu.api.get_item_category', { 'company': company })

        if (response.status < 300) {
            localStorage.setItem('itemCategory', JSON.stringify(response.data.message))
            return response.data.message
        } else {
            return "ERR"
        }
    } catch (error) {
        console.error(error);
    }
}

export const serachItems = async (company, keyword) => {
    try {
        const response = await axios.post('/api/method/sajha_menu.api.search_items', { 'keyword': keyword, 'company': company })
        if (response.status < 300) {
            return response.data.message
        } else {
            return "ERR"
        }
    } catch (error) {
        console.error(error);
    }
}