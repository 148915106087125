import React, { useState, useContext, useRef } from 'react';
import TopBar from '../../components/Topbar';

import { Modal } from 'react-bootstrap';
import CartElements from '../../components/CartElements';

import CartContext from '../../Api/Context/cartContext';
import MenuContext from '../../Api/Context/menusContext';
import { useHistory } from 'react-router';
import Spinner from 'react-bootstrap/Spinner';
import axios from 'axios';
import QueryString from 'qs';

const Index = () => {
  const componentRef = useRef();
  const history = useHistory();
  const {
    loading: cartLoading,
    cartItems,
    resetCart,
    placeOrder,
  } = useContext(CartContext);
  const { searchKeyWords } = useContext(MenuContext);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const handleClose = () => {
    setShow(false)
    setLoading(false)
  };
  const handleShow = () => setShow(true);
  const [message, setMessage] = useState(false);
  const [error, setError] = useState(false);
  const handleModal = async () => {
    setLoading(true);
    const items = cartItems.map((i) => {
      return { item_code: i.product, quantity: i.quantity };
    });
    try {
      const { data } = await axios({
        method: 'post',
        url: '/api/method/sajha_menu.api.createCurrentOrder',
        data: QueryString.stringify({
          table_name: localStorage.getItem('table_name'),
          company: localStorage.getItem('company_name'),
          orderItem: JSON.stringify(items.filter((i) => i.quantity > 0)),
        }),
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
      });
      // const { data } = await axios.post(
      //   `/api/orders/${hotel_id}/${tableCategoryId}/${tableId}`,
      //   { orders: items.filter((i) => i.quantity > 0).map(i => ({item_code: i.product, quantity:i.quantity})) },
      //   config
      // );
      setError(false);
      resetCart();
      setLoading(false);
      setMessage('Your order has been placed.');
      handleShow();
    } catch (error) {
      console.log(error);
      setError(true);
      setMessage('Something went wrong!');
      handleShow();
    }
  };

  return (
    <div>
      <div className='cartHolder'>
        <section>
          <TopBar searchShow={false} />
        </section>
        <section className='cartHeader'>
          <div className='cartHeader-items'>
            Your Items(
            {cartItems &&
              cartItems
                .map((item) => item.quantity)
                .reduce((acc, index) => acc + index, 0)}
            )
          </div>
          <div className='cartHeader-icon' onClick={resetCart}>
            {' '}
            <i class='fa fa-trash-o mr-2'></i> clear cart
          </div>
        </section>

        <section className='cartItems'>
          <CartElements ref={componentRef} cartItems={cartItems} />
        </section>

        {cartItems &&
          cartItems.length > 0 &&
          (!loading ? (
            <section className='PlaceOrder' onClick={handleModal}>
              Place Order . {" Rs."}
              {cartItems &&
              cartItems
                .map((item) => item.quantity * item.rate)
                .reduce((acc, index) => acc + index, 0)}
            
            </section>
          ) : (
            <section className='PlaceOrder'>
              {' '}
              <Spinner
                as='span'
                animation='grow'
                size='sm'
                role='status'
                aria-hidden='true'
              />{' '}
              &nbsp; Requesting...
            </section>
          ))}
      </div>

      {/* ///modal content here */}
      <Modal
        show={show}
        onHide={handleClose}
        backdrop='static'
        keyboard={false}
        size='sm'
        centered
      >
        <Modal.Body>
          <section className='successHolder'>
            <section className='successHolder-icon'>
              {!error ? (
                <i class='fa fa-check-circle '></i>
              ) : (
                <i
                  class='fa fa-exclamation-circle'
                  style={{ color: 'red' }}
                ></i>
              )}
            </section>
            <section className='successHolder-text'>{message}</section>
            <section
              className=' btn btn--primary btn--md'
              onClick={(e) => (!error ? history.goBack() : handleClose())}
            >
              okay
            </section>
          </section>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Index;
