import React, { useContext, useState, useEffect } from "react";
import CartContext from "../../Api/Context/cartContext";

const Index = ({ item }) => {
 
  const { addToCart, removeFromCart, success, cartItems } =
    useContext(CartContext);
  const [qty, setQty] = useState(0);
  const ItemExists =
    cartItems && cartItems.find((x) => x.product === item.product);

  useEffect(() => {
    if (success === true) {
      localStorage.setItem("cartItems", JSON.stringify(cartItems));
    }
    if (ItemExists) {
      setQty(ItemExists.quantity);
    }
  }, [success, qty]);

  const handleAdd = () => {
    setQty(qty + 1);
    addToCart({ ...item, qty: Number(qty + 1) });
  };
  const handleRemove = () => {
    setQty(qty - 1);
    removeFromCart(item.product);
  };
  return (
    <div>
      <div className="quantityAdder">
        {qty > 0 && (
          <div className="reducer" onClick={handleRemove}>
            -
          </div>
        )}
        <div className="status ">{qty}</div>
        <div className="adder" onClick={handleAdd}>
          +
        </div>
      </div>
    </div>
  );
};

export default Index;
