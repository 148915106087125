import React, { useReducer } from "react";
import axios from "axios";
import ComboContext from "../Context/comboContext";
import comboReducer from "../Reducers/comboReducers";

import { GET_COMBOS_SUCCESS, GET_COMBOS_FAIL } from "../types";

const ComboState = (props) => {
  const initialState = {
    loading: true,
    error: null,
    combos: null,
  };
  const [state, dispatch] = useReducer(comboReducer, initialState);

  //GET-COMBOS
  const getCombos = async (id) => {
    try {
      const { data } = await axios.get(`/api/offers/${id}/user`);
      dispatch({
        type: GET_COMBOS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GET_COMBOS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  return (
    <ComboContext.Provider
      value={{
        loading: state.loading,
        error: state.error,
        success: state.success,
        combos: state.combos,
        getCombos,
      }}
    >
      {props.children}
    </ComboContext.Provider>
  );
};

export default ComboState;
