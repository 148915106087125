import { SET_CURRENT_ROUTE, SET_PREVIOUS_ROUTE } from "../types";

const TopBarReducers = (state = { topbar: {} }, action) => {
  switch (action.type) {
    case SET_PREVIOUS_ROUTE:
      return {
        loading: false,
        previousRoute: action.payload,
      };
    case SET_CURRENT_ROUTE:
      return {
        loading: false,
        currentRoute: action.payload,
      };
    case "SET_CLICK_SLIDER":
      return {
        loading: false,
        sliderClick: action.payload,
      };
    default:
      return state;
  }
};
export default TopBarReducers;
