import React, { useReducer } from 'react';
import axios from 'axios';
import MenusContext from '../Context/menusContext';
import menusReducer from '../Reducers/menusReducers';
import {
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_SUCCESS,
  GET_RESTAURANT_FAIL,
  GET_RESTAURANT_SUCCESS,
  GET_SUB_CATEGORIES_FAIL,
  GET_SUB_CATEGORIES_SUCCESS,
  GET_SUPER_SUB_CATEGORIES_SUCCESS,
  SEARCH_FAIL,
  SEARCH_SUCCESS,
} from '../types';
import Cookies from 'js-cookie';
import qs from 'qs';

const MenusState = (props) => {
  const initialState = {
    loading: true,
    error: null,
    menus: null,
  };

  const [state, dispatch] = useReducer(menusReducer, initialState);

  //GET-A-RESTAURANT
  const getMenus = async (id) => {
    try {
      const { data } = await axios.get(`api/users/${id}/profile`);
      dispatch({
        type: GET_RESTAURANT_SUCCESS,
        payload: data,
      });
      Cookies.set('id', data._id, { expires: 7, secure: true });
    } catch (error) {
      dispatch({
        type: GET_RESTAURANT_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  //GET-A-RESTAURANT
  const getCategories = async (id) => {
    try {
      dispatch({ type: 'LOADING' });
      const { data } = await axios({
        method: 'post',
        url: '/api/method/sajha_menu.api.get_category',
        data: qs.stringify({ company: id }),
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
      });

      // const { data } = await axios.get(
      //   `/api/method/sajha_menu.api.get_category`,
      //   qs.stringify({ company: 'y2k' }),
      //   {
      //     headers: {
      //       'content-type': 'application/x-www-form-urlencoded',
      //     },
      //   }
      // );
      dispatch({
        type: GET_CATEGORIES_SUCCESS,
        payload: data.data,
      });
    } catch (error) {
      dispatch({
        type: GET_CATEGORIES_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  //GET-A-RESTAURANT
  const getSubCategories = async (catId) => {
    try {
      const { data } = await axios.get(
        `api/users/6121df58c8161d7cd2594b93/profile`
      );
      const subCategories = data.restaurant.find(
        (i) => i._id === catId
      ).sub_category;
      dispatch({
        type: GET_SUB_CATEGORIES_SUCCESS,
        payload: subCategories,
      });
    } catch (error) {
      dispatch({
        type: GET_SUB_CATEGORIES_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  //GET-A-RESTAURANT
  const getSuperSubCategories = async (id, catId, subCatId) => {
    try {
      const { data } = await axios.get(`api/users/${id}/profile`);
      const subCategories = data.restaurant.find(
        (i) => i._id === catId
      ).sub_category;
      const superSubCategories = subCategories.find(
        (i) => i._id === subCatId
      ).super_sub_category;
      dispatch({
        type: GET_SUPER_SUB_CATEGORIES_SUCCESS,
        payload: superSubCategories,
      });
    } catch (error) {
      dispatch({
        type: GET_SUB_CATEGORIES_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  //GET-A-RESTAURANT
  const getSuperSubCategoriesSearch = async (keyword) => {
    
    dispatch({ type: 'SEARCH_REQUEST' });
    let searchResults = [];
    try {
      const { data } = await axios.get(
        `api/users/${JSON.parse(localStorage.getItem('table_info')).id}/profile`
      );
      data.restaurant.forEach((category) =>
        category.sub_category.forEach(
          (sub) =>
            sub &&
            sub.super_sub_category.forEach(
              (super_sub) =>
                super_sub &&
                super_sub.items.forEach(
                  (item) =>
                    item.name
                      .toLowerCase()
                      .includes(keyword.toLowerCase().replace('%20', ' ')) &&
                    searchResults.push(item)
                )
            )
        )
      );
      // const subCategories = data.restaurant.find(
      //   (i) => i._id === catId
      // ).sub_category;
      // const superSubCategories = subCategories.find(
      //   (i) => i._id === subCatId
      // ).super_sub_category;
      dispatch({
        type: SEARCH_SUCCESS,
        payload: searchResults,
      });
    } catch (error) {
      dispatch({
        type: SEARCH_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  //GET-A-RESTAURANT
  const getSuperSubCategoriesSearchKeywords = async () => {
    dispatch({ type: 'SEARCH_KEYWORD_REQUEST' });
    let searchKeyWords = [];
    try {
      const { data } = await axios.get(
        `api/users/${JSON.parse(localStorage.getItem('table_info')).id}/profile`
      );
      data.restaurant.forEach((category) =>
        category.sub_category.forEach(
          (sub) =>
            sub &&
            sub.super_sub_category.forEach(
              (super_sub) =>
                super_sub &&
                super_sub.items.forEach(
                  (item) => item && searchKeyWords.push(item.name)
                )
            )
        )
      );

      // const subCategories = data.restaurant.find(
      //   (i) => i._id === catId
      // ).sub_category;
      // const superSubCategories = subCategories.find(
      //   (i) => i._id === subCatId
      // ).super_sub_category;
      dispatch({
        type: 'SEARCH_KEYWORD_SUCCESS',
        payload: searchKeyWords,
      });
    } catch (error) {
      dispatch({
        type: SEARCH_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  return (
    <MenusContext.Provider
      value={{
        loading: state.loading,
        error: state.error,
        success: state.success,
        menus: state.menus,
        categories: state.categories,
        subCategories: state.subcategories,
        superSubCategories: state.supersubcategories,
        searchResults: state.searchResults,
        searchKeywords: state.searchKeywords,
        searchKeySuccess: state.searchKeySuccess,
        searching: state.searching,
        getMenus,
        getCategories,
        getSubCategories,
        getSuperSubCategories,
        getSuperSubCategoriesSearch,
        getSuperSubCategoriesSearchKeywords,
      }}
    >
      {props.children}
    </MenusContext.Provider>
  );
};

export default MenusState;
