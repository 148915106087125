import {
  GET_CATEGORIES_FAIL,
  GET_CATEGORIES_SUCCESS,
  GET_RESTAURANT_FAIL,
  GET_RESTAURANT_SUCCESS,
  GET_SUB_CATEGORIES_FAIL,
  GET_SUB_CATEGORIES_SUCCESS,
  GET_SUPER_SUB_CATEGORIES_SUCCESS,
  SEARCH_SUCCESS,
} from "../types";

const MenusReducers = (state = { menus: [] }, action) => {
  switch (action.type) {
    case GET_RESTAURANT_SUCCESS:
      return {
        loading: false,
        success: true,
        menus: action.payload,
        ...ValidityState,
      };
    case "LOADING":
      return {
        loading: true,
        ...state,
      };
    case GET_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        catSuccess: true,
        categories: action.payload,
      };
    case GET_SUB_CATEGORIES_SUCCESS:
      return {
        loading: false,
        ...state,
        subCatSuccess: true,
        subcategories: action.payload,
      };
    case GET_SUPER_SUB_CATEGORIES_SUCCESS:
      return {
        loading: false,
        ...state,
        superSubCatSuccess: true,
        supersubcategories: action.payload,
      };
    case "SEARCH_REQUEST":
      return {
        ...state,
        searching: true,
      };
    case SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        searchSuccess: true,
        searchResults: action.payload,
      };
    case "SEARCH_KEYWORD_REQUEST":
      return {
        ...state,
        searchingKey: true,
      };
    case "SEARCH_KEYWORD_SUCCESS":
      return {
        ...state,
        searchKeySuccess: true,
        searchKeyWords: action.payload,
      };
    case GET_RESTAURANT_FAIL:
    case GET_CATEGORIES_FAIL:
    case GET_SUB_CATEGORIES_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default: {
      return state;
    }
  }
};

export default MenusReducers;
