import React, { useState, useEffect, useContext } from 'react';

import IndividualMenu from '../../components/IndividualMenu';
import Addcart from '../../components/CartButton/index';
import CartContext from '../../Api/Context/cartContext';
const Index = ({ item: i }) => {
  const { cartItems } = useContext(CartContext);
  return (
    <div>
      <section
        key={i.super_sub_category}
        className='menuHoldder'
        name={`${i.super_sub_category}`}
      >
      
        <h1>{i.super_sub_category}</h1>
        {i?.item_cateogry?.length > 0 ? (
          i.item_cateogry?.map((j) => <IndividualMenu item={j} />)
        ) : (
          <p>Nothing available</p>
        )}
      </section>
      {cartItems.length > 0 && <Addcart />}
    </div>
  );
};

export default Index;
