import React from 'react';

const index = () => {
  return (
    <div>
      <div className='pt-4'></div>
      <div className=' LoaderWrapper catSK-titles '></div>
      <div className='catSK-rect'>
        <div className='LoaderWrapper catSK-rect-items'></div>
        <div className='LoaderWrapper catSK-rect-items'></div>
        <div className='LoaderWrapper catSK-rect-items'></div>
        <div className='LoaderWrapper catSK-rect-items'></div>
        <div className='LoaderWrapper catSK-rect-items'></div>
        <div className='LoaderWrapper catSK-rect-items'></div>
        <div className='LoaderWrapper catSK-rect-items'></div>
        <div className='LoaderWrapper catSK-rect-items'></div>
        <div className='LoaderWrapper catSK-rect-items'></div>
      </div>
      <div className=' LoaderWrapper catSK-titles mt-5'></div>
      <div className='catSK-rect'>
        <div className='LoaderWrapper catSK-rect-items'></div>
        <div className='LoaderWrapper catSK-rect-items'></div>
        <div className='LoaderWrapper catSK-rect-items'></div>
        <div className='LoaderWrapper catSK-rect-items'></div>
        <div className='LoaderWrapper catSK-rect-items'></div>
        <div className='LoaderWrapper catSK-rect-items'></div>
        <div className='LoaderWrapper catSK-rect-items'></div>
        <div className='LoaderWrapper catSK-rect-items'></div>
        <div className='LoaderWrapper catSK-rect-items'></div>
      </div>
    </div>
  );
};

export default index;
