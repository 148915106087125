export const GET_RESTAURANT_SUCCESS = "GET_RESTAURANT_SUCCESS";
export const GET_RESTAURANT_FAIL = "GET_RESTAURANT_FAIL";
export const GET_CATEGORIES_SUCCESS = "GET_CATEGORIES_SUCCESS";
export const GET_CATEGORIES_FAIL = "GET_CATEGORIES_FAIL";
export const GET_SUB_CATEGORIES_SUCCESS = "GET_SUB_CATEGORIES_SUCCESS";
export const GET_SUB_CATEGORIES_FAIL = "GET_SUB_CATEGORIES_FAIL";
export const GET_SUPER_SUB_CATEGORIES_SUCCESS =
  "GET_SUPER_SUB_CATEGORIES_SUCCESS";
export const GET_SUPER_SUB_CATEGORIES_FAIL = "GET_SUPER_SUB_CATEGORIES_FAIL";
export const GET_COMBOS_SUCCESS = "GET_COMBOS_SUCCESS";
export const GET_COMBOS_FAIL = "GET_COMBOS_FAIL";
export const SET_PREVIOUS_ROUTE = "SET_PREVIOUS_ROUTE";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_FAIL = "SEARCH_FAIL";

export const CART_ADD_ITEM_REQUEST = "CART_ADD_ITEM_REQUEST";
export const CART_ADD_ITEM = "CART_ADD_ITEM";
export const CART_REMOVE_ITEM = "CART_REMOVE_ITEM";
export const GET_CART_REQUEST = "GET_CART_REQUEST";
export const GET_CART_SUCCESS = "GET_CART_SUCCESS";
export const GET_CART_FAIL = "GET_CART_FAIL";

export const CART_RESET = "CART_RESET";

export const ORDER_REQUEST = "ORDER_REQUEST";
export const ORDER_SUCCESS = "ORDER_SUCCESS";
export const ORDER_FAIL = "ORDER_FAIL";
