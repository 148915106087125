import React, { useReducer } from "react";
import axios from "axios";
import CartContext from "../Context/cartContext";
import CartReducer from "../Reducers/cartReducers";

import {
  GET_CART_REQUEST,
  GET_CART_FAIL,
  GET_CART_SUCCESS,
  CART_RESET,
  CART_ADD_ITEM,
  CART_ADD_ITEM_REQUEST,
  CART_REMOVE_ITEM,
  ORDER_REQUEST,
  ORDER_SUCCESS,
} from "../types";

const CartState = (props) => {
  const initialState = {
    loading: false,
    error: null,
    cartItems: JSON.parse(localStorage.getItem("cartItems")) || [],
  };
  const [state, dispatch] = useReducer(CartReducer, initialState);

  //GET-COMBOS
  const getCart = async (id) => {
    try {
      dispatch({
        type: GET_CART_REQUEST,
      });
      dispatch({
        type: GET_CART_SUCCESS,
      });
    } catch (error) {
      dispatch({
        type: GET_CART_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

  const addToCart = (data) => {
  
    const cart = JSON.parse(localStorage.getItem("cartItems"));
    dispatch({
      type: CART_ADD_ITEM_REQUEST,
    });
    try {
     
      dispatch({
        type: CART_ADD_ITEM,
        payload: {
          product: data.product,
          name: data.name,
          rate: data.rate,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  const removeFromCart = (data) => {
    const cart = JSON.parse(localStorage.getItem("cartItems"));
    try {
      dispatch({
        type: CART_REMOVE_ITEM,
        payload: {
          product: data,
        },
      });
    } catch (error) {
      console.log(error);
    }
  };

  const placeOrder = async (cartItems) => {
    const {
      id: hotel_id,
      tableCategoryId,
      tableId,
    } = JSON.parse(localStorage.getItem("table_info"));
    dispatch({
      type: ORDER_REQUEST,
    });
    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const { data } = await axios.post(
        `/api/orders/${hotel_id}/${tableCategoryId}/${tableId}`,
        { orders: cartItems.filter((i) => i.quantity > 0) },
        config
      );
      dispatch({
        type: ORDER_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: "ORDER_FAIL",
        payload: error,
      });
      console.log(error);
    }
  };

  const resetCart = () => {
    dispatch({ type: CART_RESET });
  };
  return (
    <CartContext.Provider
      value={{
        loading: state.loading,
        error: state.error,
        success: state.success,
        cartItems: state.cartItems,
        table_id: state.table_id,
        getCart,
        addToCart,
        removeFromCart,
        resetCart,
        placeOrder,
      }}
    >
      {props.children}
    </CartContext.Provider>
  );
};

export default CartState;
