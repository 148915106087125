import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import QntSwitch from '../../microComponents/qntSwitch/index';
import CartEditModal from './CartEditModal';

const Index = ({ cartItems }) => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [item, setItem] = useState({});

  const handleModal = (item) => {
    setShow(true);
    setItem(item);
  };

  const ModalHandle = ({ item }) => {
    
    return (
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <div className='modTitle'>{item.name}</div>
        </Modal.Header>
        <Modal.Body>
          <div className='adderWrapper'>
            <section className='adderItems'>
              <div className='adderItems-name'>{item.name}</div>
              <QntSwitch
                item={{ product: item.product, name: ` ${item.name}`, rate: item.rate }}
              />
            </section>
          </div>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <div>
      {cartItems &&
        cartItems.map(
          (item) =>
            item.quantity > 0 && (
              <div key={item.product}>
                <div className='cartItems-box'>
                  <div>
                    {item.quantity} X {item.name}
                  </div>
                  <div>
                    {Number(item.rate) * Number(item.quantity)}
                  </div>
                  <div
                    className='cartItems-icon'
                    onClick={(e) => handleModal(item)}
                  >
                    <i class='fa fa-edit'></i>
                  </div>
                </div>
              </div>
            )
        )}
      {/* ///modal content here */}
      <CartEditModal item={item} show={show} setShow={setShow} />
    </div>
  );
};

export default Index;
