import React from 'react';

const index = ({ image, name }) => {
  return (
    <>
      <div className='catbox'>
        <figure className='catbox-imageholder'>
          <img src={`${process.env.REACT_APP_IMAGE_URL}/${image}`} alt='' />
          <figcaption>{name}</figcaption>
        </figure>
      </div>
    </>
  );
};

export default index;
