import axios from "axios";
import React, { useReducer } from "react";
import SearchContext from "../Context/searchContext";
import searchReducer from "../Reducers/searchReducers";
import { SEARCH_FAIL, SEARCH_SUCCESS } from "../types";
const SearchState = (props) => {
  const initialState = {
    loading: true,
    searchKeyWords: [],
  };

  const [state, dispatch] = useReducer(searchReducer, initialState);
  //GET-A-RESTAURANT
  const getSuperSubCategoriesSearch = async (keyword) => {
    dispatch({ type: "SEARCH_REQUEST" });
    let searchResults = [];
    try {
      const { data } = await axios.get(
        `api/users/${JSON.parse(localStorage.getItem("table_info")).id}/profile`
      );
      data.restaurant.forEach((category) =>
        category.sub_category.forEach(
          (sub) =>
            sub &&
            sub.super_sub_category.forEach(
              (super_sub) =>
                super_sub &&
                super_sub.items.forEach(
                  (item) =>
                    item.name
                      .toLowerCase()
                      .includes(keyword.toLowerCase().replace("%20", " ")) &&
                    searchResults.push(item)
                )
            )
        )
      );
      dispatch({
        type: SEARCH_SUCCESS,
        payload: searchResults,
      });
    } catch (error) {
      dispatch({
        type: SEARCH_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  //GET-A-RESTAURANT
  const getSuperSubCategoriesSearchKeywords = async () => {
    dispatch({ type: "SEARCH_KEYWORD_REQUEST" });
    let searchKeyWords = [];
    try {
      const { data } = await axios.get(
        `api/users/${JSON.parse(localStorage.getItem("table_info")).id}/profile`
      );
      data.restaurant.forEach((category) =>
        category.sub_category.forEach(
          (sub) =>
            sub &&
            sub.super_sub_category.forEach(
              (super_sub) =>
                super_sub &&
                super_sub.items.forEach(
                  (item) => item && searchKeyWords.push(item.name)
                )
            )
        )
      );
      dispatch({
        type: "SEARCH_KEYWORD_SUCCESS",
        payload: searchKeyWords,
      });
    } catch (error) {
      dispatch({
        type: SEARCH_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };
  return (
    <SearchContext.Provider
      value={{
        searchResults: state.searchResults,
        searchSuccess: state.searchSuccess,
        searchKeyWords: state.searchKeyWords,
        getSuperSubCategoriesSearchKeywords,
        getSuperSubCategoriesSearch,
      }}
    >
      {props.children}
    </SearchContext.Provider>
  );
};

export default SearchState;
