import React, { useContext, useEffect } from 'react';
import { useParams } from 'react-router-dom';

//components

import RestroBrand from '../../components/RestroBrand';
import Addcart from '../../components/CartButton/';
import CartContext from '../../Api/Context/cartContext';

const Index = () => {
  return (
    <div>
      <h1>Welcome to sajhamenu!</h1>
    </div>
  );
};

export default Index;
