import React, { useEffect, useContext, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import SLiderMenu from '../../components/SLiderMenu';
import MenuLists from '../../components/MenuLists';
import Topbar from '../../components/Topbar';
import MenusContext from '../../Api/Context/menusContext';
import TopBarContext from '../../Api/Context/topbarContext';
import CartBox from '../../components/CartButton';
import Skeleton from 'react-loading-skeleton';
import ListSkeleton from '../../microComponents/ListSkeleton';
import { animateScroll as scroll } from 'react-scroll';
import axios from 'axios';
import qs from 'qs';

const Index = () => {
  const [subCategories, setSubCategories] = useState(null);
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  // const {sub}
  const getSubCategories = async () => {
    setLoading(true);
    try {
      const { data } = await axios({
        method: 'post',
        url: '/api/method/sajha_menu.api.get_subcategory',
        data: qs.stringify({ sub_category: state.subcategory_name }),
        headers: {
          'content-type': 'application/x-www-form-urlencoded;charset=utf-8',
        },
      });
      if (data) {
        setSubCategories(data.data);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      setLoading(false);
    }
  };
  useEffect(() => {
    getSubCategories();
    scroll.scrollToTop();
  }, []);
  return (
    <>
      <Topbar searchShow={true} />
      {!loading ? (
        subCategories && (
          <div>
            <section className='Stickybar'>
              <SLiderMenu
                super_sub_category={subCategories}
                loading={loading}
              />
            </section>

            {/* {superSubCategories &&
            superSubCategories.map((supersubcat) => (
              <MenuLists item={supersubcat} />
            ))} */}
            {subCategories &&
              subCategories.map((subCat) => <MenuLists item={subCat} />)}
          </div>
        )
      ) : (
        <ListSkeleton />
      )}
    </>
  );
};

export default Index;
