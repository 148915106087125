import React, { useState } from 'react';

import { Form, FormControl, Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { Link, useParams } from 'react-router-dom';
import Suggestion from './suggestion';
import { getItemCategory } from '../../Api/common';
import { useEffect } from 'react';
const Index = ({ data }) => {
  const [keyword, setKeyword] = useState('');
  const [show, setShow] = useState(false);
  const [searchItems, setSearchItems] = useState([])
  const history = useHistory();
  const { company_name } = useParams();

  const getItemsCategorySearch = async () => {
    let company = localStorage.getItem('company_name')
    
    const res = await getItemCategory(company ? company: company_name)
    setSearchItems(res)
  }

  useEffect(()=>{
    getItemsCategorySearch()
  },[])

  const handleKeyword = (e) => {
    setShow(true);
    setKeyword(e.target.value);
  };

  return (
    <div className='SearchForm'>
      {/* <section className='InputhWrapper'>
        <input
          type='search'
          placeholder='search here'
          className='InputhWrapper-box'
        />
      </section> */}
      <Form className='d-flex'>
        <FormControl
          type='search'
          value={keyword}
          onChange={(e) => {
            handleKeyword(e);
          }}
          placeholder='Search food'
          className='mr-2 bradL'
          aria-label='Search'
        />
        <Link to={keyword !== '' ? `/search/${keyword}` : '#'}>
          <Button variant='outline-success ' className='bradR'>
            <i className='fa fa-search'></i>
          </Button>
        </Link>
      </Form>

      <div className='SearchForm-result'>
        {show &&
          keyword !== '' &&
          searchItems
            .filter((item) =>
              item.name.toLowerCase().includes(keyword.toLowerCase())
            )
            .map((item, i) => (
              <p
                key={i}
                onClick={(e) => {
                  setKeyword('');
                  history.push(`/search/${item.name}`);
                  setShow(false);
                }}
              >
                {item.name}
              </p>
            ))}
      </div>
    </div>
  );
};

export default Index;
