import { GET_COMBOS_FAIL, GET_COMBOS_SUCCESS } from "../types";

const ComboReducer = (state = { combos: [] }, action) => {
  switch (action.type) {
    case GET_COMBOS_SUCCESS:
      return {
        loading: false,
        success: true,
        combos: action.payload,
      };
    case GET_COMBOS_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default ComboReducer;
