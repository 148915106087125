import {
  CART_RESET,
  GET_CART_REQUEST,
  GET_CART_FAIL,
  GET_CART_SUCCESS,
  CART_ADD_ITEM,
  CART_ADD_ITEM_REQUEST,
  CART_REMOVE_ITEM,
  ORDER_REQUEST,
  ORDER_SUCCESS,
} from "../types";

const CartReducer = (state = { cartItems: [], error: null }, action) => {
  switch (action.type) {
    case ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case "ORDER_FAIL":
      return {
        ...state,
        error: action.payload,
        loading: false,
      };
    case CART_ADD_ITEM_REQUEST:
      return {
        // success: false,
        cartItems: state.cartItems,
      };
    case CART_ADD_ITEM:
      const item = action.payload;
      const existItem =
        state.cartItems &&
        state.cartItems.find((x) => x.product === item.product);
      if (existItem) {
        return {
          ...state,
          success: true,
          cartItems: state.cartItems.map((x) =>
            x.product === existItem.product
              ? { ...existItem, quantity: existItem.quantity + 1 }
              : x
          ),
        };
      } else {
        return {
          success: true,
          ...state,
          cartItems: [...state.cartItems, { ...item, quantity: 1 }],
        };
      }
    case GET_CART_REQUEST:
      return {
        loading: false,
      };
    case CART_REMOVE_ITEM:
      const id = action.payload;
      const itemToRemove = state.cartItems.find(
        (x) => x.product === id.product
      );
      if (itemToRemove && itemToRemove.quantity > 1) {
        return {
          ...state,
          success: true,
          cartItems: state.cartItems.map((x) =>
            x.product === itemToRemove.product
              ? { ...itemToRemove, quantity: itemToRemove.quantity - 1 }
              : x
          ),
        };
      } else if (itemToRemove && itemToRemove.quantity === 1) {
        return {
          ...state,
          success: true,
          cartItems: state.cartItems.filter(
            (x) => x.product !== itemToRemove.product
          ),
        };
      } else {
        return {
          ...state,
          success: true,
          cartItems: state.cartItems.filter(
            (x) => x.product === itemToRemove.product
          ),
        };
      }
    case GET_CART_REQUEST:
      return {
        loading: false,
      };
    case GET_CART_SUCCESS:
      return {
        loading: false,
        cart: action.payload,
        ...state,
      };
    case GET_CART_FAIL:
      return {
        loading: false,
        error: action.payload,
      };
    case CART_RESET:
      localStorage.removeItem("cartItems");
      return {
        ...state,
        loading: false,
        reset: true,
        cartItems: [],
      };
    case "SET_TABLE_ID":
      return {
        ...state,
        table_id: action.payload,
      };
    default:
      return state;
  }
};

export default CartReducer;
