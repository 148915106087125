import React, { useContext, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Search from '../../components/Search';

import { Route } from 'react-router-dom';
import SearchContext from '../../Api/Context/searchContext';

const Index = ({ searchShow }) => {
  const history = useHistory();
  // const { searchKeyWords, getSuperSubCategoriesSearchKeywords } =
  //   useContext(SearchContext);
  // useEffect(() => {
  //   getSuperSubCategoriesSearchKeywords();
  // }, []);
  // const { id, tableCategoryId, tableId } = JSON.parse(
  //   localStorage.getItem("table_info")
  // );
  const searchKeyWords = [];
  return (
    <div
      style={{
        position: 'sticky',
        top: 0,
        background: 'white',
        zIndex: '99',
      }}
    >
      <section className='searchWrapper'>
        <div className='searchWrapper-box'>
          <Link
            href='#!'
            onClick={(e) => {
              e.preventDefault();
              history.goBack();
            }}
          >
            <button className='btn btn--sm btn--primary '>Back</button>
          </Link>
          <div style={{ flex: '1', marginLeft: 10 }}>
            {searchShow === true && (
              <Route
                render={(props) => <Search {...props} data={searchKeyWords} />}
              />
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Index;
