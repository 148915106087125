import React, { useContext } from 'react';
import TopBar from '../../components/Topbar';
import Addcart from '../../components/CartButton';
import MainCategory from '../../components/MainCategory';
import { useParams } from 'react-router-dom';
import CartContext from '../../Api/Context/cartContext';
import { useEffect } from 'react';
import { getItemCategory } from '../../Api/common';
const Index = () => {
  const { company_name, table_id } = useParams();
  localStorage.setItem('company_name', company_name);
  localStorage.setItem('table_name', table_id);
  const { cartItems } = useContext(CartContext);


  return (
    <div>
      <TopBar
        // backUrl={`${process.env.REACT_APP_PROD_URL}/${id}/home`}
        searchShow={true}
      />
      <MainCategory company_name={company_name} />
      {cartItems.length > 0 && <Addcart />}
      {/* <MainCategory /> */}
    </div>
  );
};

export default Index;
